export default {
  "titles": {
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخدمات"])},
    "register_step_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل-الخطوة-1"])},
    "register_step_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل-الخطوة-2"])},
    "register_step_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل-الخطوة-3"])},
    "restorepassword_check_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استعادة كلمة المرور التحقق من الرمز"])},
    "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير كلمة المرور"])},
    "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الباقات"])},
    "appointment_requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلبات المواعيد"])},
    "order_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الطلب"])},
    "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييماتى"])}
  },
  "Auth": {
    "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل دخول"])},
    "mobile_and_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل رقم الجوال وكلمة المرور"])},
    "phone_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجوال / البريد الإلكترونى"])},
    "mobile_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجوال"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الالكتروني"])},
    "forget_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل نسيت كلمة المرور ؟"])},
    "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ليس لديك حساب ؟"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء حساب"])},
    "enter_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل بيانات المركز"])},
    "center_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شعار المركز"])},
    "pictures_credits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صور المركز والاعتمادات"])},
    "name_center_arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المركز باللغة العربية"])},
    "name_center_english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المركز باللغة الانجليزية"])},
    "name_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المسئول"])},
    "center_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم جوال المركز"])},
    "officials_mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم جوال المسئول"])},
    "license_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الرخصة"])},
    "tax_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم الضريبى"])},
    "geographical_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموقع الجغرافي"])},
    "select_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر دولة"])},
    "sections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأقسام"])},
    "the_specialization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصنيف الخدمات"])},
    "description_experiences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف والخبرات"])},
    "defin_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد كلمة المرور"])},
    "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موافق على"])},
    "terms_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشروط والاحكام"])},
    "already_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بالفعل لديك حساب"])},
    "continuation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استمرار"])},
    "activate_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفعيل حساب"])},
    "enter_verification_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل كود التحقق"])},
    "verification_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود التحقق"])},
    "not_receive_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يصلك كود"])},
    "enter_your_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل بياناتك"])},
    "upload_picture_doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارفع صورة الطبيب"])},
    "bank_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل بيانات الحساب البنكي"])},
    "bank_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم البنك"])},
    "iban_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الآيبان"])},
    "account_holders_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم صاحب الحساب"])},
    "account_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الحساب"])},
    "data_sent_administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال بياناتك للإدارة سيتم الرد عليك"])},
    "restore_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استعادة كلمة المرور"])},
    "enter_mobile_receive_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل رقم الجوال لتتلقى الكود"])},
    "enter_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل كلمة المرور الجديدة"])},
    "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور الجديدة"])},
    "definitely_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد كلمة المرور الجديدة"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
    "logged_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تسجيل الدخول بنجاح"])},
    "please_log_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى تسجيل الدخول اولا"])},
    "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اهلا"])},
    "Loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جارى التحميل ..."])}
  },
  "Sidebar": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخدمات"])},
    "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الباقات"])},
    "appointment_requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلبات المواعيد"])},
    "times_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مواعيد العمل"])},
    "external_appointments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواعيد الخارجية"])},
    "my_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بياناتي"])},
    "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييماتي"])},
    "financial_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعاملات المالية"])},
    "order_reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقارير الطلبات"])},
    "user_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صلاحيات المستخدمين"])},
    "about_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عن التطبيق"])},
    "terms_and_Conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشروط والأحكام"])},
    "connect_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
    "sign_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل خروج"])},
    "aksir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكسير"])},
    "center_control_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحة تحكم المركز"])}
  },
  "datatable": {
    "servname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الخدمة"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر"])},
    "section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القسم"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التصنيف"])},
    "number_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الجلسات"])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقسيط"])},
    "picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة"])},
    "package_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الباقة"])},
    "user_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المستخدم"])},
    "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصلاحيات"])}
  },
  "orders": {
    "new_appointments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواعيد الجديدة"])},
    "current_appointments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواعيد الحالية"])},
    "expired_appointments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواعيد المنتهية"])},
    "new_orders_and_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا يمكنك رؤية الطلبات الجديدة وتفاصيلها"])},
    "see_the_current_one_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا يمكنك رؤية الحالية وتفاصيلها"])},
    "see_finished_orders_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا يمكنك رؤية الطلبات المنتهية وتفاصيلها"])},
    "Search_customer_name_mobile_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابحث برقم الطلب /اسم العميل / رقم الجوال"])},
    "order_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الطلب"])},
    "order_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صاحب الطلب"])},
    "order_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيمة الطلب"])},
    "Booking_application_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيمة الحجز(عمولة التطبيق)"])},
    "value_Added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيمة المضافة"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاجمالى"])},
    "the_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التاريخ"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جديد"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالي"])},
    "finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منتهي"])},
    "new_order_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الطلب الجديد"])},
    "current_order_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الطلب الحالي"])},
    "details_completed_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الطلب المنتهي"])},
    "service_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الخدمة"])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملاحظات"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طريقة الدفع"])},
    "appointment_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الموعد"])},
    "order_summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملخص الطلب"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
    "reservation_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد الحجز"])},
    "suggest_another_appointment_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اقتراح موعد آخر للعميل"])},
    "no_records_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يعثر على أية سجلات"])},
    "booking_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موعد الحجز"])},
    "send_appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال الموعد"])},
    "appointment_sent_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال الموعد للعميل سيتم الرد عليك"])},
    "amount_paid_order_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم دفع باقي المبلغ وبدأ الطلب"])},
    "request_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الانتهاء من الطلب"])},
    "view_customer_rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض تقييم العميل"])},
    "the_working_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا تعرض مواعيد العمل"])},
    "canceled_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلبات الملغية"])},
    "here_canceled_orders_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا يمكنك رؤية الطلبات الملغية وتفاصيلها"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سنة"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل"])},
    "appointment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الموعد"])},
    "Appointment_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت الموعد"])},
    "tax_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيمة الضريبة"])},
    "discount_coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كوبون الخصم"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر الإجمالي"])},
    "Payment_required_coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المطلوب دفعه من العميل (من خلال التطبيق)"])},
    "remaining_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ المتبقي(مطلوب دفعه عند الزيارة)"])}
  },
  "Glopal": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
    "statistics_displayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا تعرض الإحصائيات"])},
    "total_number_reservations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الحجوزات الكلي"])},
    "new_reservations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحجوزات الجديدة"])},
    "current_reservations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحجوزات الحالية"])},
    "expired_reservations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحجوزات المنتهية"])},
    "number_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الخدمات"])},
    "number_packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الباقات"])},
    "services_offered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا تعرض الخدمات"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل"])},
    "cancel_appearance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء ظهور"])},
    "hide_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إخفاء السعر"])},
    "show_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اظهار السعر"])},
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقييمات"])},
    "add_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة خدمة"])},
    "upload_picture_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارفع صورة الخدمة"])},
    "service_name_arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الخدمة باللغة العربية"])},
    "service_name_english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الخدمة باللغة الانجليزية"])},
    "service_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر الخدمة"])},
    "choose_service_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر سعر الخدمة"])},
    "doctor_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الطبيب"])},
    "choose_doctor_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر اسم الطبيب"])},
    "select_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر القسم"])},
    "select_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر التصنيف"])},
    "choose_number_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر عدد الجلسات"])},
    "service_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف الخدمة"])},
    "enter_service_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل وصف الخدمة"])},
    "terms_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شروط الخدمة"])},
    "choose_terms_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل شروط الخدمة"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا"])},
    "available_installments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل الخدمة متاحة للتقسيط ؟"])},
    "service_added_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إضافة الخدمة بنجاح"])},
    "service_edited_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تعديل الخدمة بنجاح"])},
    "do_you_want_delete_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل بالفعل تريد حذف الخدمة؟"])},
    "service_deleted_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حذف الخدمة بنجاح"])},
    "edit_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل خدمة"])},
    "picture_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة الخدمة"])},
    "customer_reviews_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييمات العملاء على الخدمة"])},
    "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييما"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فلتر"])},
    "highest_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأعلى سعر"])},
    "lowest_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأقل  سعر"])},
    "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متاح"])},
    "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير متاح"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجنس"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ذكر"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنثى"])},
    "to_be_sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد"])},
    "cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الغاء"])},
    "packages_displayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا تعرض الباقات"])},
    "add_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة باقة"])},
    "upload_picture_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارفع صورة الباقة"])},
    "package_name_arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الباقة باللغة العربية"])},
    "package_name_english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الباقة باللغة الانجليزية"])},
    "package_expiry_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ انتهاء الباقة"])},
    "select_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الخدمات"])},
    "Price_total_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر الخدمات الكلي"])},
    "discount_percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة الخصم"])},
    "enter_discount_percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل نسبة الخصم"])},
    "percentage_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ان تكون نسبة الخصم اقل من سعر الخدمات"])},
    "rs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ر.س"])},
    "package_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف الباقة"])},
    "enter_package_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل وصف الباقة"])},
    "package_terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شروط الباقة"])},
    "enter_package_terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل شروط الباقة"])},
    "package_available_installments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل الباقة متاحة للتقسيط؟"])},
    "Choose_gender_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الجنس الموجه له الباقة"])},
    "want_delete_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل بالفعل تريد حذف الباقة؟"])},
    "package_deleted_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حذف الباقة بنجاح"])},
    "edit_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل باقة"])},
    "save_edits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ التعديلات"])},
    "price_after_discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر بعد الخصم"])},
    "package_modified_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تعديل الباقة بنجاح"])},
    "customer_reviews_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييمات العملاء على الباقة"])},
    "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السبت"])},
    "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأحد"])},
    "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاثنين"])},
    "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الثلاثاء"])},
    "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأر بعاء"])},
    "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخميس"])},
    "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجمعة"])},
    "customer_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييمات العميل"])},
    "clinic_evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييم على العيادة"])},
    "service_evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييم الخدمة"])},
    "working_periods_displayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا تعرض فترات العمل"])},
    "outpatient_clinic_appointments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواعيد الخارجية للعيادة"])},
    "clinic_outpatient_appointments_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا تعرض المواعيد الخارجية للعيادة"])},
    "book_appointment_slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل تريد حجز فترة موعد؟"])},
    "appointment_booked_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حجز الموعد بنجاح"])},
    "reservation_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل مدة الحجز"])},
    "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ساعة"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اليوم"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الى"])},
    "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أجازة"])},
    "modify_central_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا يمكنك تعديل البيانات المركزية"])},
    "center_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات المركز"])},
    "Doctors_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الطبيب"])},
    "bank_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحساب البنكي"])},
    "here_modify_center_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا يمكنك تعديل بيانات المركز"])},
    "here_edit_your_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا يمكنك تعديل بياناتك"])},
    "modify_bank_account_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا يمكنك تعديل بيانات الحساب البنكي"])},
    "center_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المركز"])},
    "current_Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور الحالية"])},
    "new_mobile_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجوال الجديد"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال"])},
    "Change_mobile_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير رقم الجوال"])},
    "mobile_number_changed_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم نغيير رقم الجوال بنجاح"])},
    "modifications_saved_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حفظ التعديلات بنجاح"])},
    "old_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور القديمة"])},
    "password_changed_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم نغيير كلمة المرور بنجاح"])},
    "image_doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة الطبيب"])},
    "name_doctor_Arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الطبيب باللغة العربية"])},
    "name_doctor_english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الطبيب باللغة الإنجليزية"])},
    "customer_reviews_presented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا تعرض تقييمات العملاء عليك"])},
    "financial_transactions_displayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا تعرض المعاملات المالية الخاصة بك"])},
    "your_wallet_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رصيد محفظتك"])},
    "settlement_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب تسوية"])},
    "settlement_request_sent_dministration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال طلب التسوية للإدارة وسيتم الرد عليك"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل"])},
    "download_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل pdf"])},
    "download_xl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل xl"])},
    "reports_orders_displayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا تعرض تقارير عن طلباتك"])},
    "permissions_displayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا تعرض صلاحيات المستخدمين"])},
    "add_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة مستخدم"])},
    "package_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة الباقات"])},
    "Service_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة الخدمات"])},
    "order_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة الطلبات"])},
    "appointment_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة المواعيد"])},
    "user_added_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إضافة المستخدم بنجاح"])},
    "do_you_want_to_delete_the_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل بالفعل تريد حذف المستخدم؟"])},
    "The_user_has_been_deleted_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حذف المستخدم بنجاح"])},
    "edit_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل مستخدم"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاشعارات"])},
    "here_see_the_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا يمكنك رؤية الاشعارات"])},
    "no_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد اشعارات"])},
    "communicate_administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا يمكنك التواصل مع الإدارة"])},
    "the_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسالة"])},
    "Please_enter_your_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال رسالتك"])},
    "details_about_the_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا تعرض تفاصيل عن التطبيق"])},
    "Here_terms_and_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا تعرض الشروط والأحكام"])},
    "there_no_appointments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد مواعيد"])},
    "no_available_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد خيارات متاحة"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التفاصيل"])},
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اظهار"])},
    "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جلسات"])},
    "single_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدمات"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكل"])}
  },
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عربي 2222"])},
  "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمضان"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملف الشخصى"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عن"])},
  "Newroute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رابط جديد"])},
  "tesssst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["testtt ar"])}
}