export default {
  "titles": {
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["services"])},
    "register_step_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["register-step-1"])},
    "register_step_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["register-step-2"])},
    "register_step_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["register-step-3"])},
    "restorepassword_check_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore password check-code"])},
    "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
    "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packages"])},
    "appointment_requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointment requests"])},
    "order_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["orderDetails"])},
    "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My reviews"])}
  },
  "Auth": {
    "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
    "mobile_and_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your mobile number and password"])},
    "phone_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile number / email"])},
    "mobile_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile number"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "forget_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Did you forget your password ?"])},
    "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't have an account?"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account"])},
    "enter_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter center data"])},
    "center_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Center logo"])},
    "pictures_credits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pictures of the center and credits"])},
    "name_center_arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The center name in arabic"])},
    "name_center_english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The center name in english"])},
    "name_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the manager"])},
    "center_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Center mobile number"])},
    "officials_mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The official's mobile number"])},
    "license_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License number"])},
    "tax_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax number"])},
    "geographical_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geographical location"])},
    "select_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select country"])},
    "sections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sections"])},
    "the_specialization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classification of services"])},
    "description_experiences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description and experiences"])},
    "defin_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definitely the password"])},
    "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree to"])},
    "terms_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The terms and conditions"])},
    "already_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You already have an account"])},
    "continuation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuation"])},
    "activate_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate the account"])},
    "enter_verification_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the verification code"])},
    "verification_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification code"])},
    "not_receive_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You did not receive a code"])},
    "enter_your_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your data"])},
    "upload_picture_doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload a picture of the doctor"])},
    "bank_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter bank account information"])},
    "bank_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank name"])},
    "iban_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iban number"])},
    "account_holders_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account holder's name"])},
    "account_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account number"])},
    "data_sent_administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your data has been sent to the administration to respond to you"])},
    "restore_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore password"])},
    "enter_mobile_receive_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your mobile number to receive the code"])},
    "enter_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the new password"])},
    "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
    "definitely_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definitely a new password"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["save"])},
    "logged_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have been logged in successfully"])},
    "please_log_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please log in first"])},
    "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello"])},
    "Loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading ..."])}
  },
  "Sidebar": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages"])},
    "appointment_requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointment requests"])},
    "times_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["times of work"])},
    "external_appointments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External appointments"])},
    "my_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My data"])},
    "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My reviews"])},
    "financial_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial transactions"])},
    "order_reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order reports"])},
    "user_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User permissions"])},
    "about_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the application"])},
    "terms_and_Conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and conditions"])},
    "connect_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect with us"])},
    "sign_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out"])},
    "aksir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aksir"])},
    "center_control_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Center control panel"])}
  },
  "datatable": {
    "servname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service name"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the price"])},
    "section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["section"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["category"])},
    "number_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of sessions"])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installment"])},
    "picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["picture"])},
    "package_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package name"])},
    "user_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["user name"])},
    "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])}
  },
  "orders": {
    "new_appointments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New appointments"])},
    "current_appointments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current appointments"])},
    "expired_appointments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired appointments"])},
    "new_orders_and_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can see new orders and their details"])},
    "see_the_current_one_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can see the current one and its details"])},
    "see_finished_orders_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can see finished orders and their details"])},
    "Search_customer_name_mobile_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by order number / customer name / mobile number"])},
    "order_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["order number"])},
    "order_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["order owner"])},
    "order_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["order value"])},
    "Booking_application_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking value(application commission)"])},
    "value_Added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value Added"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "the_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the date"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["current"])},
    "finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["finished"])},
    "new_order_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New order details"])},
    "current_order_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current order details"])},
    "details_completed_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details of the completed order"])},
    "service_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service details"])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comments"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payment method"])},
    "appointment_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointment details"])},
    "order_summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order summary"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the address"])},
    "reservation_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reservation confirmation"])},
    "suggest_another_appointment_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggest another appointment for the client"])},
    "no_records_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No records found"])},
    "booking_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking date"])},
    "send_appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["send appointment"])},
    "appointment_sent_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The appointment has been sent to the client. You will be contacted"])},
    "amount_paid_order_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The rest of the amount has been paid and the order has been started"])},
    "request_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The request has been completed"])},
    "view_customer_rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View customer rating"])},
    "the_working_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here are the working hours"])},
    "canceled_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceled orders"])},
    "here_canceled_orders_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can see canceled orders and their details"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["year"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["details"])},
    "appointment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointment date"])},
    "Appointment_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointment time"])},
    "tax_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax value"])},
    "discount_coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount coupon"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total price"])},
    "Payment_required_coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment required from the customer (through the application)"])},
    "remaining_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining amount (required to be paid upon visit)"])}
  },
  "Glopal": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "statistics_displayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here the statistics are displayed"])},
    "total_number_reservations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total number of reservations"])},
    "new_reservations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New reservations"])},
    "current_reservations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current reservations"])},
    "expired_reservations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired reservations"])},
    "number_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of services"])},
    "number_packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of packages"])},
    "services_offered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here are the services offered"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit"])},
    "cancel_appearance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel appearance"])},
    "hide_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide price"])},
    "show_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["show price"])},
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratings"])},
    "add_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add service"])},
    "upload_picture_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload a picture of the service"])},
    "service_name_arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service name in arabic"])},
    "service_name_english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service name in english"])},
    "service_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service price"])},
    "choose_service_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose service price"])},
    "doctor_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor's name"])},
    "choose_doctor_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the doctor's name"])},
    "select_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select section"])},
    "select_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the category"])},
    "choose_number_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the number of sessions"])},
    "service_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service description"])},
    "enter_service_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the service description"])},
    "terms_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Service"])},
    "choose_terms_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the terms of service"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no"])},
    "available_installments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is the service available for installments ?"])},
    "service_added_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The service has been added successfully"])},
    "service_edited_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The service has been modified successfully"])},
    "do_you_want_delete_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete the service?"])},
    "service_deleted_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The service has been deleted successfully"])},
    "edit_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit service"])},
    "picture_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["picture of the service"])},
    "customer_reviews_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer reviews on the service"])},
    "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filter"])},
    "highest_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highest price"])},
    "lowest_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lowest price"])},
    "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available"])},
    "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unavailable"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["male"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
    "to_be_sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to be sure"])},
    "cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancellation"])},
    "packages_displayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here are the packages displayed"])},
    "add_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add package"])},
    "upload_picture_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload a photo of the package"])},
    "package_name_arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The package name is in Arabic"])},
    "package_name_english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package name in English"])},
    "package_expiry_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package expiry date"])},
    "select_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select services"])},
    "Price_total_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price of total services"])},
    "discount_percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discount percentage"])},
    "enter_discount_percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the discount percentage"])},
    "percentage_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The discount percentage must be less than the price of the services"])},
    "rs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rs"])},
    "package_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package description"])},
    "enter_package_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the package description"])},
    "package_terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package terms"])},
    "enter_package_terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the package terms"])},
    "package_available_installments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is the package available for installments?"])},
    "Choose_gender_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the gender the package is intended for"])},
    "want_delete_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete the package?"])},
    "package_deleted_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The package has been deleted successfully"])},
    "edit_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit package"])},
    "save_edits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save edits"])},
    "price_after_discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The price after discount"])},
    "package_modified_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The package has been modified successfully"])},
    "customer_reviews_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer reviews on the package"])},
    "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday"])},
    "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])},
    "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
    "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
    "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday"])},
    "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])},
    "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["friday"])},
    "customer_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer reviews"])},
    "clinic_evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinic evaluation"])},
    "service_evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service evaluation"])},
    "working_periods_displayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here the working periods are displayed"])},
    "outpatient_clinic_appointments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outpatient clinic appointments"])},
    "clinic_outpatient_appointments_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here are the clinic's outpatient appointments"])},
    "book_appointment_slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to book an appointment slot?"])},
    "appointment_booked_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The appointment has been booked successfully"])},
    "reservation_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the reservation duration"])},
    "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hour"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["today"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
    "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vacation"])},
    "modify_central_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can modify the central data"])},
    "center_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Center data"])},
    "Doctors_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor's data"])},
    "bank_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank account"])},
    "here_modify_center_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can modify the center data"])},
    "here_edit_your_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can edit your data"])},
    "modify_bank_account_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can modify the bank account information"])},
    "center_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Center name"])},
    "current_Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Password"])},
    "new_mobile_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New mobile number"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["send"])},
    "Change_mobile_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change mobile number"])},
    "mobile_number_changed_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The mobile number has been changed successfully"])},
    "modifications_saved_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The modifications were saved successfully"])},
    "old_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old Password"])},
    "password_changed_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password has been changed successfully"])},
    "image_doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image of a doctor"])},
    "name_doctor_Arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The name of the doctor in Arabic"])},
    "name_doctor_english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor's name in English"])},
    "customer_reviews_presented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here customer reviews are presented to you"])},
    "financial_transactions_displayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here your financial transactions are displayed"])},
    "your_wallet_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your wallet balance"])},
    "settlement_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settlement request"])},
    "settlement_request_sent_dministration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The settlement request has been sent to the administration and you will be responded to"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["download"])},
    "download_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["download pdf"])},
    "download_xl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["download xl"])},
    "reports_orders_displayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here reports on your orders are displayed"])},
    "permissions_displayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here the user permissions are displayed"])},
    "add_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add user"])},
    "package_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package management"])},
    "Service_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service management"])},
    "order_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order management"])},
    "appointment_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointment management"])},
    "user_added_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User added successfully"])},
    "do_you_want_to_delete_the_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete the user?"])},
    "edit_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit user"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notifications"])},
    "here_see_the_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can see the notifications"])},
    "no_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No notifications"])},
    "communicate_administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can communicate with the administration"])},
    "the_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the message"])},
    "Please_enter_your_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your message"])},
    "details_about_the_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you will find details about the application"])},
    "Here_terms_and_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here are the terms and conditions"])},
    "there_no_appointments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no appointments"])},
    "no_available_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no available options"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the details"])},
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["show"])},
    "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sessions"])},
    "single_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["services"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all"])}
  },
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["welcome from en"])},
  "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ٌRamadan"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "Newroute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NewRoute"])},
  "tesssst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["testtt en"])}
}